import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/reminder.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageReminderCreate: FC<PageProps & EspaceProps & UserProps> = function ({
  espace,
  user,
}) {
  const model = new GenericData({
    espace,
    params,
    collectionName: 'reminders',
    espaceId: espace.id,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          itemPathnamePrefix={`/espaces/${espace.id}/tasks/reminders/`}
          model={model}
          name="reminder"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageReminderCreate);
